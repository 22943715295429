import './App.css';

function App() {
  return (
    <div className="App">
      <h1 className='text-xl font-bold py-10'>鉄板とおばんざい irodori</h1>

      <div>
        <img className='w-1/2 m-auto' src="/assets/logo.png" alt="" />
      </div>

      <p>〒616-8227 京都府京都市右京区常盤草木町３−４</p>
      <p>準備中</p>
      <p>代表 : 大島直人</p>
    </div>
  );
}

export default App;
